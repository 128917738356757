import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import NewsList from './components/NewsList';
import Pagination from './components/Pagination';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import Footer from './components/Footer';
import NotFound from './components/NotFound'; // Import the custom 404 component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const [news, setNews] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const newsPerPage = 9;

  const backgroundImageUrl = 'https://threatinsights.org/morning_-_Mountain_Morning_-_Illustration_Background.jpg';

  // Fetch news from the backend for the current page
  const fetchNews = async (page) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`https://threatinsights.org/api/news?page=${page}`);
      setNews(response.data.news);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error('Error fetching news:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNews(currentPage);
  }, [currentPage]);

  const handleSearchResults = (filteredNews) => {
    setSearchResults(filteredNews);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Router>
      <Helmet>
        <title>{`Latest Cybersecurity News & Vulnerability Alerts - Page ${currentPage} | ThreatInsights`}</title>
        <meta name="description" content="Stay updated with the latest cybersecurity news, vulnerability disclosures, threat alerts, and expert insights." />
        <meta property="og:title" content="ThreatInsights: Latest Cybersecurity News & Vulnerability Alerts" />
        <meta property="og:url" content="https://www.threatinsights.org" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.threatinsights.org/logo.png" />

        {/* Preload the background image */}
        <link rel="preload" as="image" href={backgroundImageUrl} />
      </Helmet>

      <div className="relative min-h-screen overflow-hidden">
        {/* Scenic Background with overlay */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: 'cover', // Ensures the image covers the entire container
            backgroundPosition: 'center', // Centers the image
            backgroundRepeat: 'no-repeat', // Prevents repeating the image
            backgroundAttachment: 'fixed',
            backgroundColor: '#000000' // Fallback color
          }}
        >
          <div className="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div className="relative z-10 text-gray-100 min-h-[calc(100vh-100px)]">
          <Header />

          <Routes>
            <Route
              path="/"
              element={
                <div className="container mx-auto px-4 py-10">
                  <SearchBar onSearch={handleSearchResults} />
                  {searchResults.length > 0 ? (
                    <>
                      <h2 className="text-xl font-bold text-gray-100 mt-6">Search Results</h2>
                      <NewsList news={searchResults} isLoading={isLoading} />
                    </>
                  ) : (
                    <>
                      <h2 className="text-xl font-bold text-gray-200 mt-6">All News</h2>
                      <NewsList news={news} isLoading={isLoading} />
                      <Pagination
                        newsPerPage={newsPerPage}
                        totalNews={news.length * totalPages}
                        paginate={paginate}
                        currentPage={currentPage}
                        totalPages={totalPages}
                      />
                    </>
                  )}
                </div>
              }
            />
            {/* Custom 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </Router>
  );
}

export default App;

