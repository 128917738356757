import React from 'react';
import { FaLink } from 'react-icons/fa';

const NewsCard = ({ item }) => {
  const maxCvesToShow = 3;
  const cveList = Array.isArray(item.cve_id)
    ? item.cve_id
    : item.cve_id ? item.cve_id.split(', ') : [];
  const truncatedCves = cveList.length > maxCvesToShow
    ? cveList.slice(0, maxCvesToShow).join(', ') + '...'
    : cveList.join(', ');

  // Define tag background color based on the source
  const getSourceTagColor = (source) => {
    if (source.toLowerCase().includes('ubuntu')) {
      return 'bg-orange-500'; // Orange for Ubuntu
    } else if (source.toLowerCase().includes('darkreading')) {
      return 'bg-blue-600'; // Blue for DarkReading
    } else if (source.toLowerCase().includes('hackernews')) {
      return 'bg-green-500'; // Green for HackerNews
    } else {
      return 'bg-gray-500'; // Default gray for others
    }
  };

  return (
    <div className="relative bg-sky-950 shadow-md p-3 rounded-lg hover:shadow-lg transition-transform duration-300 transform hover:-translate-y-1 hover:scale-102 text-white">
      {/* Source Tag */}
      <span className={`absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-semibold text-white z-10 ${getSourceTagColor(item.source)}`}>
        {item.source}
      </span>

      <div className="relative z-0 pt-5">
        {/* Title */}
        <h2 className="text-base font-bold text-white mb-2 px-1">{item.title}</h2>

        {/* Description */}
        <p className="text-gray-400 text-s mb-2 px-1 line-clamp-2">{item.description}</p>

        {/* CVE IDs (truncated if needed) */}
        {truncatedCves && (
          <p className="text-xs text-green-600 mb-1 px-1">CVE IDs: {truncatedCves}</p>
        )}

        {/* CVSS Score (conditionally rendered) */}
        {item.cvss_score && (
          <p className="text-xs text-red-400 mb-2 px-1">CVSS Score: {item.cvss_score}</p>
        )}

        {/* Date Added */}
        <p className="text-gray-500 text-xs mb-3 px-1">Date: {new Date(item.date_added).toLocaleDateString()}</p>

        {/* Read More Link */}
        <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 text-xs flex items-center hover:text-blue-800">
          <FaLink className="mr-1" /> Read More
        </a>
      </div>
    </div>
  );
};

export default NewsCard;
