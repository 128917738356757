import React from 'react';

const Pagination = ({ newsPerPage, totalNews, paginate, currentPage, totalPages }) => {
  const pageNumbers = [];
  const maxPagesToShow = 5; // Number of visible pages in the pagination bar

  // Calculate start and end pages
  let startPage = Math.max(currentPage - 2, 1);
  let endPage = Math.min(currentPage + 2, totalPages);

  if (startPage === 1) {
    endPage = Math.min(5, totalPages);
  }
  if (endPage === totalPages) {
    startPage = Math.max(totalPages - 4, 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="flex justify-center mt-4">
      <ul className="inline-flex items-center space-x-1">
        {/* Previous Button */}
        {currentPage > 1 && (
          <li>
            <button
              onClick={() => paginate(currentPage - 1)}
              className="px-2 py-1 text-sm rounded-md bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-800 transition ease-in-out duration-150"
            >
              &larr; Prev
            </button>
          </li>
        )}

        {/* First Page */}
        {startPage > 1 && (
          <>
            <li>
              <button
                onClick={() => paginate(1)}
                className={`px-2 py-1 text-sm rounded-md transition ease-in-out duration-150 ${
                  currentPage === 1 ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-800'
                }`}
              >
                1
              </button>
            </li>
            <li>
              <span className="px-2 text-gray-400">...</span>
            </li>
          </>
        )}

        {/* Page Numbers */}
        {pageNumbers.map((number) => (
          <li key={number}>
            <button
              onClick={() => paginate(number)}
              className={`px-2 py-1 text-sm rounded-md transition ease-in-out duration-150 ${
                currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-800'
              }`}
            >
              {number}
            </button>
          </li>
        ))}

        {/* Last Page */}
        {endPage < totalPages && (
          <>
            <li>
              <span className="px-2 text-gray-400">...</span>
            </li>
            <li>
              <button
                onClick={() => paginate(totalPages)}
                className={`px-2 py-1 text-sm rounded-md transition ease-in-out duration-150 ${
                  currentPage === totalPages ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-800'
                }`}
              >
                {totalPages}
              </button>
            </li>
          </>
        )}

        {/* Next Button */}
        {currentPage < totalPages && (
          <li>
            <button
              onClick={() => paginate(currentPage + 1)}
              className="px-2 py-1 text-sm rounded-md bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-800 transition ease-in-out duration-150"
            >
              Next &rarr;
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
