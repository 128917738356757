import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-8 relative z-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-start">

          {/* About Section */}
          <div className="w-full md:w-1/3 mb-6">
            <h2 className="text-xl font-bold text-white mb-4">Threat Insights</h2>
            <p className="text-gray-400">
              Threat Insights provides the latest updates and in-depth articles on cybersecurity threats, vulnerabilities, and prevention measures. Our mission is to help businesses stay informed and secure.
            </p>
          </div>

          {/* Quick Links */}
          <div className="w-full md:w-1/3 mb-6">
            <h2 className="text-xl font-bold text-white mb-4">Quick Links</h2>
            <ul className="text-gray-400">
              <li className="mb-2">
                <a href="#articles" className="hover:text-white transition duration-200">Articles</a>
              </li>
              <li className="mb-2">
                <a href="#blogs" className="hover:text-white transition duration-200">Blogs</a>
              </li>
              <li className="mb-2">
                <a href="#resources" className="hover:text-white transition duration-200">Resources</a>
              </li>
              <li>
                <a href="#contact" className="hover:text-white transition duration-200">Contact Us</a>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="w-full md:w-1/3 mb-6">
            <h2 className="text-xl font-bold text-white mb-4">Get in Touch</h2>
            <p className="text-gray-400 mb-2">Email: <a href="mailto:info@threatinsights.org" className="text-sky-400 hover:text-white transition duration-200">info@threatinsights.org</a></p>
            <p className="text-gray-400">Follow us:</p>
            <div className="flex space-x-4 mt-2">
              <a href="https://twitter.com/threatinsights" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M22.46 6c-.77.35-1.59.58-2.46.69a4.28 4.28 0 001.88-2.37 8.56 8.56 0 01-2.72 1.04A4.27 4.27 0 0015.5 4a4.27 4.27 0 00-4.18 5.28A12.11 12.11 0 012.29 4.87a4.27 4.27 0 001.32 5.71 4.24 4.24 0 01-1.93-.53v.05a4.27 4.27 0 003.42 4.19 4.26 4.26 0 01-1.92.07 4.27 4.27 0 003.99 2.97A8.6 8.6 0 012 19.54 12.13 12.13 0 008.1 21a12.11 12.11 0 0012.36-12.36v-.56c.85-.61 1.59-1.36 2.17-2.21a8.56 8.56 0 01-2.45.67z" />
                </svg>
              </a>
              <a href="https://linkedin.com/company/threatinsights" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 8a6 6 0 00-11.9 2 6 6 0 0011.9 0zM10 14H4v-6h6v6zm2 0h6v-6h-6v6z" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-700 pt-4 mt-8 text-center text-gray-500">
          <p>&copy; {new Date().getFullYear()} Threat Insights. All Rights Reserved.</p>
          <p>
            <a href="#terms" className="hover:text-white transition duration-200">Terms of Service</a> | <a href="#privacy" className="hover:text-white transition duration-200">Privacy Policy</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
