import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';


const NotFound = () => {
  const backgroundImageUrl = 'https://threatinsights.org/morning_-_Mountain_Morning_-_Illustration_Background.jpg';

  return (
    <>
      <div className="relative min-h-screen overflow-hidden">
        {/* Scenic Background with overlay */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${backgroundImageUrl})`, backgroundColor: '#000000' }}
        >
          <div className="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div className="relative z-10 text-gray-100 min-h-[calc(100vh-100px)]">
          <Header />

          <div className="container mx-auto px-4 py-10 text-center">
            <h1 className="text-4xl font-bold text-white mb-4 mt-20 p-10">404 - Page Not Found</h1>
            <p className="text-lg text-gray-300 mb-6">
              Oops! The page you're looking for doesn't exist.
            </p>
            <Link to="/" className="text-blue-500 hover:underline">
              Go back to the homepage
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;

