import React from 'react';

const Header = () => {
  return (
    <header className="fixed top-0 left-0 right-0 bg-gray-900 bg-opacity-90 z-10 shadow-md">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {/* Logo Icon with Hover Effect */}
            <div className="relative w-10 h-10 transform transition-transform duration-300 hover:scale-110">
              <svg viewBox="0 0 100 100" className="w-full h-full">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="#38bdf8" />
                  <stop offset="100%" stopColor="#0284c7" />
                  </linearGradient>
                </defs>
                <circle cx="50" cy="50" r="48" fill="url(#gradient)" className="animate-pulse" />
                <path d="M30 35 L50 25 L70 35 L70 65 L50 75 L30 65 Z" fill="black" />
                <text x="50" y="62" fontSize="20" fontWeight="bold" fill="white" textAnchor="middle">TI</text>
              </svg>
            </div>

            {/* Site Title with T and I in White */}
            <h1
              className="text-xl font-semibold tracking-wide"
              style={{ fontFamily: "'Montserrat', sans-serif", letterSpacing: '0.1em' }}
            >
              <span className="text-white">T</span>
              <span className="text-sky-400">HREAT </span>
              <span className="text-white">I</span>
              <span className="text-sky-400">NSIGHTS</span>
            </h1>
          </div>

          {/* Navigation Links */}
          <nav className="flex space-x-6">
            <a
              href="https://threatinsights.org/blog/"
              className="text-white hover:text-sky-400 transition duration-300 text-lg font-medium"
            >
              Articles
            </a>
          </nav>

        </div>
      </div>
    </header>
  );
};

export default Header;

