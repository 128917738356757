import React from 'react';
import NewsCard from './NewsCard';

const NewsList = ({ news, isLoading }) => {
  const placeholders = Array.from({ length: 9 }); // Number of placeholders to show

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {isLoading
        ? placeholders.map((_, index) => (
            <div
              key={index}
              className="bg-gray-800 p-4 rounded-lg h-40 animate-pulse"
            ></div> // Placeholder card
          ))
        : news.map((item, index) => <NewsCard key={index} item={item} />)}
    </div>
  );
};

export default NewsList;

