import React, { useState } from 'react';
import axios from 'axios';
import { Search, X, SlidersHorizontal } from 'lucide-react';

const SearchBar = ({ onSearch }) => {
  const [title, setTitle] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [source, setSource] = useState('');
  const [minCvss, setMinCvss] = useState('');
  const [maxCvss, setMaxCvss] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    if (title.trim() === '' && source.trim() === '' && minCvss === '' && maxCvss === '') return;

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://threatinsights.org/api/news/all', {
        params: {
          title: title || undefined,
          source: source || undefined,
          cvss_min: minCvss || undefined,
          cvss_max: maxCvss || undefined,
        },
      });

      const filteredNews = response.data.news;
      onSearch(filteredNews);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setError('Error fetching search results.');
      onSearch([]);
    } finally {
      setLoading(false);
    }
  };

  const clearSearch = () => {
    setTitle('');
    setSource('');
    setMinCvss('');
    setMaxCvss('');
    setError(null);
    onSearch([]);
  };

  const handleMinCvssChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 10)) {
      setMinCvss(value);
    }
  };

  const handleMaxCvssChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 10)) {
      setMaxCvss(value);
    }
  };

  return (
    <div className="bg-gray-900 dark:bg-gray-900 p-3 rounded-md shadow-md mb-2 mt-12 mx-4">
      <div className="flex items-center space-x-2">
        {/* Search Input */}
        <div className="flex-grow flex items-center bg-gray-800 dark:bg-gray-800 rounded-md px-3 py-1">
          <Search className="h-4 w-4 text-gray-500" />
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Search by title..."
            className="ml-2 bg-transparent text-sm text-gray-200 dark:text-gray-200 focus:outline-none placeholder-gray-500"
          />
        </div>

        {/* Toggle Filters Button */}
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="p-1 bg-gray-800 dark:bg-gray-800 rounded-md text-gray-200 dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700 transition"
        >
          <SlidersHorizontal className="h-4 w-4" />
        </button>

        {/* Search Button */}
        <button
          onClick={handleSearch}
          disabled={loading}
          className="px-4 py-1 bg-blue-600 text-white rounded-md text-sm hover:bg-blue-500 transition"
        >
          {loading ? 'Loading...' : 'Search'}
        </button>

        {/* Clear Button */}
        <button
          onClick={clearSearch}
          className="p-1 bg-red-700 dark:bg-gray-800 rounded-md text-gray-200 dark:text-white hover:bg-red-400 dark:hover:bg-gray-700 transition"
        >
          <X className="h-4 w-4" />
        </button>
      </div>

      {/* Filter Section */}
      {showFilters && (
        <div className="mt-3 border-t border-gray-300 dark:border-gray-700 pt-3">
          <div className="grid grid-cols-2 gap-4">
            {/* Source Filter */}
            <div>
              <input
                type="text"
                value={source}
                onChange={(e) => setSource(e.target.value)}
                placeholder="Source"
                className="w-full bg-gray-800 dark:bg-gray-800 rounded-md px-3 py-1 text-sm text-gray-200 dark:text-white placeholder-gray-500"
              />
            </div>

            {/* Min and Max CVSS */}
            <div className="flex space-x-2">
              <input
                type="number"
                value={minCvss}
                onChange={handleMinCvssChange}
                placeholder="Min CVSS"
                className="w-full bg-gray-800 dark:bg-gray-800 rounded-md px-3 py-1 text-sm text-gray-200 dark:text-white placeholder-gray-500"
                min="0"
                max="10"
              />
              <input
                type="number"
                value={maxCvss}
                onChange={handleMaxCvssChange}
                placeholder="Max CVSS"
                className="w-full bg-gray-800 dark:bg-gray-800 rounded-md px-3 py-1 text-sm text-gray-200 dark:text-white placeholder-gray-500"
                min="0"
                max="10"
              />
            </div>
          </div>
        </div>
      )}

      {/* Error Message */}
      {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}
    </div>
  );
};

export default SearchBar;
